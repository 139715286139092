import { erpMethodApi, erpResourceApi, googleMapApi, msGraphApi } from '@dladio/service';
import { storeConnector } from '@dladio/app-bridge';
import { Action, combineReducers, configureStore, ThunkAction } from '@reduxjs/toolkit';
import { localStore } from '@dladio/hooks';

export const store = configureStore({
    reducer: combineReducers({
        localStore: localStore,
        [storeConnector.reducerPath]: storeConnector.reducer,
        [erpMethodApi.reducerPath]: erpMethodApi.reducer,
        [erpResourceApi.reducerPath]: erpResourceApi.reducer,
        [msGraphApi.reducerPath]: msGraphApi.reducer,
        [googleMapApi.reducerPath]: googleMapApi.reducer
    }),
    middleware: (getDefaultMiddleware) => {
        return getDefaultMiddleware({
            serializableCheck: false
        })
            .concat(erpMethodApi.middleware)
            .concat(erpResourceApi.middleware)
            .concat(googleMapApi.middleware)
            .concat(msGraphApi.middleware)
    }
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;
