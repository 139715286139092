import { useToast } from "@chakra-ui/react";
import { AutoCompleteTextField } from "@dladio/core-ui";
import { useInput, useLocalStore } from "@dladio/hooks";
import { DefaultButton, Dialog, DialogFooter, PrimaryButton, Stack, TextField, Text } from "@fluentui/react";
import _ from "lodash";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { createJournalEntry, createReconcile, getAccountNameAction, getReconcileLines } from "../../actions/bank-reconciliation.action";

const AddJournalEntryDialog = () => {

    const { select, setStore } = useLocalStore('br')
    const dispatch = useDispatch()
    const toast = useToast()
    const { form, handleOnChange, handleOnChangeForValue, clearAllFields, setFormData } = useInput()
    const [accountName, setAccountName]: any = useState('')
    const memo = useRef<any>('')

    useEffect(() => {
        if (select('is-open-je-modal')) {
            generateModal(_.get(select('selected-statement'), 'Amount') || 0, select('total-transactions.sysTotal') || 0)
            getAccountName()

        }
    }, [select('is-open-je-modal')])

    const getAccountName = async () => {
        let accName: any = await dispatch(getAccountNameAction(select("selected-statement")?.AcctCode) as any)
        setAccountName(accName?.value)
    }

    const generateModal = (amount: any, totalTransaction: any) => {

        if ((amount - totalTransaction) < 0) {
            setFormData({
                Credit: 0,
                Debit: (Math.abs(+(amount - totalTransaction))).toFixed(4)
            })

        } else {
            setFormData({
                Credit: (Math.abs((+amount - totalTransaction))).toFixed(4),
                Debit: 0,
            })
        }
    }

    const memoChangeHandler = (event: any) => {
        memo.current = event?.target?.value
    }

    const createJournalEntryHandler = async () => {
        let paymentEntryModel: any = {
            "Memo": memo.current,
            "JournalEntryLines": [
                {
                    AccountCode: select('selected-statement.AcctCode'),
                    Credit: +form["Debit"],
                    Debit: +form["Credit"]
                },
                {
                    ...form,
                    Credit: +form["Credit"],
                    Debit: +form["Debit"]
                }
            ]
        }

        let res: any = await dispatch(createJournalEntry(paymentEntryModel, toast) as any)

        let brModel: any = {
            "ExternalReconciliation": {
                "ReconciliationAccountType": "rat_GLAccount",
                "ReconciliationBankStatementLines": [
                    {
                        "BankStatementAccountCode": select('selected-statement').AcctCode,
                        "Sequence": select('selected-statement').Sequence
                    },
                ],
                "ReconciliationJournalEntryLines": await getReconcileLines([
                    ...(select('selected-list')) ? (select('selected-list')) : [],
                    { TransactionId: res.Number, LineNumber: 0 }
                ])
            }
        }
        if (res) {
            let error: any = await dispatch(createReconcile(brModel, toast) as any)
            if (error) {
                //await dispatch(cancelJournalEntry(paymentEntryModel, res?.Number, toast))
            }
        }
        setStore('selected-list', [])
        setStore('is-open-je-modal', false)
    }

    return (
        // @ts-ignore
        <Dialog
            containerClassName={'lg:max-w-full lg:w-[400px]'}
            title={'Adjustment Entry'}
            isOpen={select('is-open-je-modal')}
            isBlocking={true}
            onDismiss={() => {
                setStore('is-open-je-modal', false)
            }}
        >
            <Stack>
                <Stack style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                    <Text className={'text-black'} >Statement bank account</Text>
                    <TextField disabled value={accountName || ''} />
                </Stack>
                <Stack style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Text className={'text-black'} >Amount</Text>
                    <TextField disabled value={select('selected-statement')?.Amount || ''} />
                </Stack>

                <TextField className={'text-black'} onChange={memoChangeHandler}
                    name={'Memo'}
                    label="Memo" />
                <Stack horizontal className={'gap-2'}>
                    <AutoCompleteTextField
                        label={'Account'}
                        id={'AccountCode'} onChange={handleOnChangeForValue}
                        options={{ type: "erp", query: { name: '8753c7c1cd' } }}
                        keyValuePair={{ key: "key", text: 'text' }}
                    />
                    {
                        form?.Debit == 0 ? (
                            <TextField className={'text-black'} value={form["Credit"]} onChange={handleOnChange}
                                name={'Credit'}
                                label="Credit" />) :
                            (<TextField className={'text-black'} value={form["Debit"]} onChange={handleOnChange}
                                name={'Debit'}
                                label="Debit" />)
                    }
                </Stack>
            </Stack>

            <DialogFooter>
                <PrimaryButton
                    onClick={createJournalEntryHandler} text="Create"
                />
                <DefaultButton
                    onClick={() => {
                        setStore('is-open-je-modal', false)
                    }} text="Close"
                />
            </DialogFooter>
        </Dialog>
    )
}

export default AddJournalEntryDialog