import { AutoCompleteTextField, Box, Stack } from "@dladio/core-ui";
import { useLocalStore } from "@dladio/hooks";
import { DatePicker, Dropdown, FontIcon } from "@fluentui/react";
import moment from "moment";
import { useEffect, useRef } from "react";

const FilterPanel = () => {
    const { setStore } = useLocalStore('filters')
    const toRef = useRef(null);

    useEffect(() => {
        return () => {
            setStore({})
        }
    }, [])

    const toHandler = (value: any) => {
        setStore('to', moment(value).format('YYYY-MM-DD'))
        if (value) setStore('to', moment(value).format('YYYY-MM-DD'))
        else setStore('to', moment(new Date()).format('YYYY-MM-DD'))
    }

    const onSourceChange = (e: any, item: any) => {
        setStore('source', item?.key)
    }

    const onSourceFromHandler = (key: any, value: any) => {
        setStore('sourceFrom', value)
    }

    const clearFilter = () => {
        setStore({})
    }

    return (
        <Box>
            <Stack className={'gap-2 align-bottom'}>
                <Dropdown
                    style={{ width: '15vw' }}
                    label={"Source"}
                    defaultSelectedKey={'Account'}
                    id={'source'}
                    onChange={onSourceChange}
                    options={[{ key: 'Account', text: 'Account' }]}
                />
                <AutoCompleteTextField
                    label={'Account'}
                    defaultKey={''}
                    id={'source_from'} onChange={onSourceFromHandler}
                    options={{ type: "erp", query: { name: '80cc20f4c5' } }}
                    keyValuePair={{ key: "key", text: 'text' }}
                />
                <DatePicker
                    label={'To'}
                    componentRef={toRef}
                    allowTextInput
                    ariaLabel="Select a date"
                    onSelectDate={toHandler as (date: Date | null | undefined) => void}
                />
                <FontIcon
                    className={'ps-1 flex flex-col mb-2 justify-end'}
                    aria-label="Settings" iconName="ChromeClose"
                    style={{ fontSize: 12, cursor: 'pointer' }}
                    onClick={clearFilter}
                />
            </Stack>
        </Box>
    )
}

export default FilterPanel