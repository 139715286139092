import { Box } from "@dladio/core-ui";
import { useAutoFit, useLocalStore } from "@dladio/hooks";
import { SERVICE, useDerive } from "@dladio/service";
import { DetailsList, DetailsListLayoutMode, FontIcon, ISelection, Selection } from "@fluentui/react";
import _ from "lodash";
import { useEffect } from "react";

const OpenInvoiceList = () => {
    const { select, setStore } = useLocalStore("br");
    const [ref, setAutoFit] = useAutoFit()

    const { value } = useDerive('exec', {
        options: {
            method: SERVICE.EXEC_QUERY_METHOD,
            body: { name: 'bf9b785e6f' }
        },
        path: 'message'
    })

    console.log(value, '---');


    useEffect(() => {
        setTimeout(() => {
            setAutoFit()
        }, 200)
    }, [])

    const columns: any = [
        {
            key: '1',
            name: '#',
            fieldName: 'DocEntry',
            minWidth: 50,
            maxWidth: 50,
            isResizable: true
        },
        {
            key: '2',
            name: 'Invoice #',
            fieldName: 'DocNum',
            minWidth: 100,
            maxWidth: 100,
            isResizable: true
        },
        {
            key: '3',
            name: 'Amount',
            fieldName: 'DocTotal',
            minWidth: 100,
            maxWidth: 100,
            isResizable: true
        },
        {
            key: '4',
            name: 'Posting Date',
            fieldName: 'DocDate',
            minWidth: 110,
            maxWidth: 110,
            isResizable: true
        },
        {
            key: '5',
            name: 'BP  #',
            fieldName: 'CardCode',
            minWidth: 200,
            isResizable: true
        },
        {
            key: '6',
            name: 'BP Name',
            fieldName: 'CardName',
            minWidth: 200,
            isResizable: true
        },
        {
            key: '7',
            name: 'BP Group',
            fieldName: 'Group',
            minWidth: 200,
            isResizable: true
        },
        {
            key: '8',
            name: 'Action',
            fieldName: 'Action',
            minWidth: 200,
            isResizable: true
        },
    ]

    const markAsPaid = (column: any) => {
        console.log(column);
        
        setStore('marks-as-paid.isOpen', true)
        setStore('marks-as-paid.docEntry', column?.DocEntry)
    }

    const onRenderItemColumn = (obj: any, index: any, column: any) => {
        const fieldContent = _.get(obj, column.fieldName);
        switch (column.fieldName) {
            case 'Action':
                return (
                    <div className={'cursor-pointer'} onClick={() => markAsPaid(obj)}><FontIcon className="w-[4px] h-[4px]" iconName="Completed" /></div>
                )
            default:
                return (
                    <>{fieldContent}</>
                )
        }
    }

    return (
        <Box>
            <div ref={ref} className={"overflow-auto"}>
                <DetailsList
                    items={value ?? []}
                    columns={columns}
                    compact={true}
                    setKey="none"
                    onRenderItemColumn={onRenderItemColumn}
                    layoutMode={DetailsListLayoutMode.justified}
                    selectionMode={0}
                />
            </div>
        </Box>
    )

}

export default OpenInvoiceList