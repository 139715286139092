import { useToast } from "@chakra-ui/react";
import { useAutoFit, useLocalStore } from "@dladio/hooks";
import { SERVICE, useDerive } from "@dladio/service";
import { isArray, multiSearch } from "@dladio/utils";
import { CommandBar, DetailsList, DetailsListLayoutMode, DetailsRow, ISelection, SearchBox, Stack, Selection } from "@fluentui/react";
import _ from "lodash";
import { useEffect, useRef, useState } from "react";

const MatchComponent = () => {
    const { model, select } = useLocalStore("br");

    console.log(model, '------');


    const { value } = useDerive('exec', {
        options: {
            method: SERVICE.EXEC_QUERY_METHOD,
            body: {
                name: '388091c495',
                id: select('selected-statement.AcctCode')
            }
        }, path: 'message'
    })

    return (
        <div className={'bg-white rounded-2 m-2 pt-1'}>
            <MatchTable data={value} />
        </div>
    )
}

const MatchTable = ({ data }: any) => {
    const { select, setStore } = useLocalStore("br");
    const [searchResult, setSearchResult] = useState()
    const [ref, setAutoFit] = useAutoFit()
    const toast = useToast();
    const [items, setItems]: any = useState([])
    const selection: { current: ISelection } = useRef(new Selection({
        onSelectionChanged,
    }))

    useEffect(() => {
        setSearchResult(data)
    }, [data])

    useEffect(() => {
        setTimeout(() => {
            setAutoFit()
        }, 200)
    }, [])

    function onSelectionChanged() {
        setItems(selection.current.getSelection())
    }

    const columns: any = [
        {
            key: '1',
            name: 'Trans: Id',
            fieldName: 'TransactionId',
            minWidth: 50,
            maxWidth: 50,
            isResizable: true
        },
        {
            key: '2',
            name: 'Posting Date',
            fieldName: 'PostingDate',
            minWidth: 100,
            maxWidth: 100,
            isResizable: true
        },
        {
            key: '3',
            name: 'Ref.',
            fieldName: 'Ref1',
            minWidth: 100,
            maxWidth: 100,
            isResizable: true
        },
        {
            key: '4',
            name: 'Amount',
            fieldName: 'Amount',
            minWidth: 110,
            maxWidth: 110,
            isResizable: true
        },
        {
            key: '5',
            name: 'Memo',
            fieldName: 'Memo',
            minWidth: 200,
            isResizable: true
        },
    ]

    const searchTextChangeHandler = (e: any, text: any) => {
        const filteredLines = text && data.length > 0 ? multiSearch(data, text) : data
        setSearchResult(filteredLines)
    }

    const onRenderItemColumn = (obj: any, index: any, column: any) => {
        const fieldContent = _.get(obj, column.fieldName);
        return (
            <>{fieldContent}</>
        )
    }

    const addItemsHandler = () => {
        selection?.current?.setAllSelected(false)
        if (select('selected-list')) {
            let model: any = [...select('selected-list')]
            items.map((item: any) => {
                let isExist: any = model.includes(item)
                if (isExist) {
                    toast({
                        title: `Trans: ID ${item.TransactionId} Already exist`,
                        status: "error",
                        duration: 2500,
                        position: "bottom-end",
                        isClosable: true,
                    })
                } else {
                    model.push(item)
                }
            })
            setStore('selected-list', [...model])
        } else {
            setStore('selected-list', [...items])
        }
    }

    const filterSelectedLines = (list: any) => {
        let selectedList = select('selected-list')
        let filteredList = isArray(list) && list?.filter((line: any) => {
            let model = selectedList?.find((item: any) => item?.TransactionId == line?.TransactionId)
            return _.isEmpty(model)
        })

        return filteredList
    }

    const _onRenderRow = (props: any) => {

        if (props) {
            return <div onDoubleClick={() => {
                selection?.current?.setAllSelected(false)
                let selectedList = select('selected-list')
                if (isArray(selectedList)) {
                    setStore('selected-list', [...selectedList, props?.item])
                } else {
                    setStore('selected-list', [props?.item])
                }
            }}>
                <DetailsRow {...props} />
            </div>;
        }
        return null;
    };

    return (
        <div className={'bg-white rounded-2'}>
            <Stack className="" horizontal>
                <SearchBox onChange={searchTextChangeHandler} className="m-2 rounded-2" placeholder="Search" />
                <CommandBar
                    className={'w-full'}
                    items={[
                        {
                            key: '0',
                            text: 'Add',
                            iconProps: { iconName: 'Add' },
                            onClick: addItemsHandler
                        }
                    ]}
                />
            </Stack>
            <div ref={ref} className={"overflow-auto"}>
                <DetailsList
                    items={filterSelectedLines(searchResult || [])}
                    columns={columns}
                    compact={true}
                    setKey="none"
                    selection={selection.current}
                    onRenderItemColumn={onRenderItemColumn}
                    onRenderRow={_onRenderRow}
                    layoutMode={DetailsListLayoutMode.justified}
                    selectionMode={2}
                />
            </div>
        </div>
    )

}

export default MatchComponent