import { useToast } from "@chakra-ui/react"
import { AutoCompleteTextField } from "@dladio/core-ui"
import { useInput, useLocalStore } from "@dladio/hooks"
import { erpMethodApi, SERVICE, useDerive } from "@dladio/service"
import { DefaultButton, Dialog, DialogFooter, PrimaryButton, Stack, TextField } from "@fluentui/react"
import { useDispatch } from "react-redux"

const MarkAsPaidDialog = () => {

    const { select } = useLocalStore('br')

    const { value } = useDerive('exec', {
        options: {
            method: SERVICE.EXEC_SAP_SERVICE,
            body: {
                "method": "GET",
                "endpoint": `Invoices(${select('marks-as-paid.docEntry')})`,
            }
        }, path: 'message'
    })

    let model = { ...value, BalanceDue: value?.DocTotal - value?.PaidToDate }

    console.log('23423423423', value);


    return (
        <>
            <Form model={model} />
        </>
    )

}

const Form: any = ({ model }: any) => {
    const { select, setStore } = useLocalStore('br')
    const dispatch = useDispatch()
    const toast = useToast()
    const { form, handleOnChange, handleOnChangeForValue, clearAllFields } = useInput()

    const paidOnChangeHandler = (event: any) => {
        let balanceDue = ((model?.DocTotal - model?.PaidToDate) - (+event.target.value || 0)).toFixed(4)
        handleOnChangeForValue('BalanceDue', balanceDue)
        handleOnChangeForValue('paid', event.target.value)
    }

    const MarkAsPaymentHandler = async () => {
        if (model?.DocTotal) {
            const { error, data }: any = await dispatch(
                erpMethodApi.endpoints.execMethodApi.initiate({
                    method: 'generate_payment_model_for_sales_invoice_by_transfer_account',
                    body: { model: { ...model, ...form } },
                }) as any
            );
            if (error) {
                toast({
                    title: "Failed",
                    description: error?.data?.message?.message?.value,
                    status: "error",
                    duration: 2500,
                    position: "bottom-end",
                    isClosable: true,
                })
            }

            const { error: sapError, data: response }: any = await dispatch(
                erpMethodApi.endpoints.execMutationApi.initiate({
                    method: SERVICE.EXEC_SAP_SERVICE,
                    body: {
                        method: "POST",
                        endpoint: "IncomingPayments",
                        body: data?.message
                    },
                }) as any
            );
            // dispatch(hideLoading());
            clearAllFields()
            sapError ? toast({
                title: "Failed",
                description: sapError?.data?.message?.message?.value,
                status: "error",
                duration: 2500,
                position: "bottom-end",
                isClosable: true,
            }) : toast({
                title: "Succeed",
                status: "success",
                duration: 2500,
                position: "bottom-end",
                isClosable: true,
            });

        } else {
            // dispatch(hideLoading());
            toast({
                title: "Document total is zero",
                status: "error",
                duration: 2500,
                position: "bottom-end",
                isClosable: true,
            });
        }

        // dispatch(showConfirmDialog({
        //     title: 'Confirmation Required!',
        //     subtitle: 'Are you sure you want to continue with the action',
        //     onConfirm: async () => {
        //         setStore('marks-as-paid.isOpen', false)
        //         dispatch(showLoading("Loading..."));
        //         if (model?.DocTotal) {
        //             const { error, data }: any = await dispatch(
        //                 erpMethodApi.endpoints.execMethodApi.initiate({
        //                     method: 'generate_payment_model_for_sales_invoice_by_transfer_account',
        //                     body: { model: { ...model, ...form } },
        //                 }) as any
        //             );
        //             if (error) {
        //                 toast({
        //                     title: "Failed",
        //                     description: error?.data?.message?.message?.value,
        //                     status: "error",
        //                     duration: 2500,
        //                     position: "bottom-end",
        //                     isClosable: true,
        //                 })
        //             }

        //             const { error: sapError, data: response }: any = await dispatch(
        //                 erpMethodApi.endpoints.execMutationApi.initiate({
        //                     method: SERVICE.EXEC_SAP_SERVICE,
        //                     body: {
        //                         method: "POST",
        //                         endpoint: "IncomingPayments",
        //                         body: data?.message
        //                     },
        //                 }) as any
        //             );
        //             // dispatch(hideLoading());
        //             clearAllFields()
        //             sapError ? toast({
        //                 title: "Failed",
        //                 description: sapError?.data?.message?.message?.value,
        //                 status: "error",
        //                 duration: 2500,
        //                 position: "bottom-end",
        //                 isClosable: true,
        //             }) : toast({
        //                 title: "Succeed",
        //                 status: "success",
        //                 duration: 2500,
        //                 position: "bottom-end",
        //                 isClosable: true,
        //             });

        //         } else {
        //             // dispatch(hideLoading());
        //             toast({
        //                 title: "Document total is zero",
        //                 status: "error",
        //                 duration: 2500,
        //                 position: "bottom-end",
        //                 isClosable: true,
        //             });
        //         }
        //     }
        // }))
    }

    return (
        <>
            {/* @ts-ignore */}
            <Dialog
                containerClassName={'lg:max-w-full lg:w-[450px]'}
                title={'Payment'}
                isOpen={select('marks-as-paid.isOpen')}
                isBlocking={true}
                onDismiss={() => {
                    setStore('marks-as-paid.isOpen', false)
                    clearAllFields()
                }}
            >
                <Stack horizontal className={'gap-3'}>
                    <Stack className={'w-100'}>
                        <AutoCompleteTextField
                            label={'GL Account'}
                            id={'TransferAccount'} onChange={handleOnChangeForValue}
                            options={{ type: "erp", query: { name: '8753c7c1cd' } }}
                            keyValuePair={{ key: "key", text: 'text' }}
                        />
                        <TextField className={'text-black'}
                            value={form["transfer_date"]} type={'date'}
                            onChange={handleOnChange}
                            name={'TransferDate'}
                            label="Transfer Date" />
                        <TextField className={'text-black'} value={form["Remarks"]} onChange={handleOnChange}
                            name={'Remarks'}
                            label="Remarks" />

                    </Stack>
                    <Stack className={'w-100'}>
                        <TextField className={'text-black'} value={form["BalanceDue"] || model.BalanceDue}
                            onChange={handleOnChange}
                            name={'BalanceDue'}
                            label="Balance Due" />
                        <TextField className={'text-black'} value={form["paid"]} onChange={paidOnChangeHandler}
                            name={'paid'}
                            label="Paid" />
                    </Stack>
                </Stack>

                <DialogFooter>
                    <PrimaryButton
                        onClick={MarkAsPaymentHandler}
                        text="Create"
                    />
                    <DefaultButton
                        onClick={() => {
                            setStore('marks-as-paid.isOpen', false)
                            clearAllFields()
                        }} text="Close"
                    />
                </DialogFooter>
            </Dialog>
        </>
    )
}

export default MarkAsPaidDialog