import { useToast } from "@chakra-ui/react";
import { Box, Stack } from "@dladio/core-ui";
import { useLocalStore } from "@dladio/hooks";
import { SERVICE, useDerive } from "@dladio/service";
import { isArray } from "@dladio/utils";
import _ from "lodash";
import { useDispatch } from "react-redux";
import { createReconcile, getReconcileLines } from "../actions/bank-reconciliation.action";
import { DefaultButton, Text } from "@fluentui/react"

const Banner = () => {
    const { select, setStore } = useLocalStore('br')
    const dispatch = useDispatch()
    const toast = useToast();

    //get total transactions
    const { value, isError } = useDerive('exec', {
        options: {
            method: SERVICE.EXEC_QUERY_METHOD,
            body: {
                name: 'd2eb8e7c00',
                account: _.get(select('selected-statement'), 'AcctCode'),
                value: isArray(select('selected-list')) && select('selected-list')?.reduce((p: any, c: any) => p + `,${c?.TransactionId}`, select('selected-list')[0]?.TransactionId)
            }
        },
        path: 'message'
    })

    const reconcileActionHandler = async () => {

        if ((_.get(select('selected-statement'), 'Amount') - value?.sysTotal) == 0) {

            let model = {
                "ExternalReconciliation": {
                    "ReconciliationAccountType": "rat_GLAccount",
                    "ReconciliationBankStatementLines": [
                        {
                            "BankStatementAccountCode": select('selected-statement').AcctCode,
                            "Sequence": select('selected-statement').Sequence
                        },
                    ],
                    "ReconciliationJournalEntryLines": await getReconcileLines(select('selected-list'))
                }
            }

            dispatch(createReconcile(model, toast) as any)
            setStore('selected-list', [])

        } else {
            setStore('total-transactions', value)
            setStore('is-open-je-modal', true)
        }
    }

    const selectedTransactionCount = () => {
        let list = select('selected-list')
        if (isArray(list)) return list.length
        return 0
    }

    return (
        <Box>
            <div className='flex gap-10 items-center divide-x divide-dashed'>
                <Stack className="flex-col">
                    <Text>Sequence</Text>
                    <Text className='font-semibold'>{_.get(select('selected-statement'), 'Sequence') ?? 0}</Text>
                </Stack>
                <Stack className='ps-2 flex-col'>
                    <Text>Debit</Text>
                    <Text className='font-semibold'>{(_.get(select('selected-statement'), 'DebAmount')) ?? 0}</Text>
                </Stack>
                <Stack className='ps-2 flex-col'>
                    <Text>Credit</Text>
                    <Text className='font-semibold'>{(_.get(select('selected-statement'), 'CredAmnt')) ?? 0}</Text>
                </Stack>
                <Stack className='ps-2 flex-col'>
                    <Text>Total Transactions</Text>
                    <Text className='font-semibold'>{!isError ? (_.get(value, 'value')) ?? 0 : 0}</Text>
                </Stack>
                <Stack className='ps-2 flex-col'>
                    <Text>Transaction Count</Text>
                    <Text className='font-semibold'>{selectedTransactionCount()}</Text>
                </Stack>
                <Stack className='ps-2 flex-col'>
                    <Text>Difference</Text>
                    <Text
                        className='font-semibold'>{Number(((_.get(select('selected-statement'), 'Amount') ?? 0) - (!isError ? _.get(value, 'sysTotal') ?? 0 : 0)))?.toFixed(4)}</Text>
                </Stack>
                <Stack className='ps-2 items-end flex-grow'>
                    <DefaultButton onClick={() => {
                        reconcileActionHandler()
                    }}>Reconcile</DefaultButton>
                </Stack>
            </div>
        </Box>
    )
}

export default Banner