import { DefaultPivot, PivotItem } from "@dladio/core-ui"
import MatchComponent from "./components/Match"
import OpenInvoiceList from "./components/OpenInvoiceList"
import PrepaymentOrders from "./components/PrepaymentOrders"
import SelectedJEList from "./components/SelectedJeList"

const MainView = () => {
    return (
        <DefaultPivot>
            <PivotItem headerText="Find & Match">
                <MatchComponent />
            </PivotItem>
            <PivotItem headerText="Open Invoices">
                <OpenInvoiceList />
            </PivotItem>
            <PivotItem headerText="Prepayment Orders">
                <PrepaymentOrders />
            </PivotItem>
            <PivotItem headerText="Selected List">
                <SelectedJEList />
            </PivotItem>
        </DefaultPivot>
    )
}

export default MainView