import { DeleteIcon } from "@chakra-ui/icons";
import { Box } from "@dladio/core-ui";
import { useAutoFit, useLocalStore } from "@dladio/hooks";
import { DetailsList, DetailsListLayoutMode, ISelection, Selection } from "@fluentui/react";
import _ from "lodash";
import { useEffect } from "react";

const SelectedJEList = ({ data }: any) => {
    const { select, setStore } = useLocalStore("br");
    const [ref, setAutoFit] = useAutoFit()

    useEffect(() => {
        setTimeout(() => {
            setAutoFit()
        }, 200)
    }, [])

    useEffect(() => {
        if (select('selected-list')?.length <= 0) {
            setStore('total-transactions', {})

        }
    }, [select('selected-list')])

    let selection: ISelection = new Selection({
        onSelectionChanged,
    }
    )

    function onSelectionChanged() {
    }

    const columns: any = [
        {
            key: '1',
            name: 'Trans: Id',
            fieldName: 'TransactionId',
            minWidth: 50,
            maxWidth: 50,
            isResizable: true
        },
        {
            key: '2',
            name: 'Posting Date',
            fieldName: 'PostingDate',
            minWidth: 100,
            maxWidth: 100,
            isResizable: true
        },
        {
            key: '3',
            name: 'Ref.',
            fieldName: 'Ref1',
            minWidth: 100,
            maxWidth: 100,
            isResizable: true
        },
        {
            key: '4',
            name: 'Amount',
            fieldName: 'Amount',
            minWidth: 110,
            maxWidth: 110,
            isResizable: true
        },
        {
            key: '5',
            name: 'Memo',
            fieldName: 'Memo',
            minWidth: 200,
            isResizable: true
        },
        {
            key: '6',
            name: 'Action',
            fieldName: 'Action',
            minWidth: 200,
            isResizable: true
        },
    ]

    const deleteActionHandler = (index: any) => {
        let model: any = [...select('selected-list')]
        model.splice(index, 1)
        setStore('selected-list', model)
    }

    const onRenderItemColumn = (obj: any, index: any, column: any) => {
        const fieldContent = _.get(obj, column.fieldName);
        switch (column.fieldName) {
            case 'Action':
                return (
                    <div className={'cursor-pointer'} onClick={() => deleteActionHandler(index)}><DeleteIcon w={4} h={4} /></div>
                )
            default:
                return (
                    <>{fieldContent}</>
                )
        }
    }

    return (
        <Box>
            <div className={'bg-white rounded-2'}>
                <div ref={ref} className={"overflow-auto"}>
                    <DetailsList
                        items={select('selected-list') || []}
                        columns={columns}
                        compact={true}
                        setKey="none"
                        selection={selection}
                        onRenderItemColumn={onRenderItemColumn}
                        layoutMode={DetailsListLayoutMode.justified}
                        selectionMode={0}
                    />
                </div>
            </div>
        </Box>
    )

}

export default SelectedJEList