import { Box } from "@dladio/core-ui";
import { useAutoFit, useLocalStore } from "@dladio/hooks";
import { SERVICE, useDerive } from "@dladio/service";
import { DetailsList, DetailsListLayoutMode, FontIcon } from "@fluentui/react";
import _ from "lodash";
import { useEffect } from "react";

const PrepaymentOrders = () => {
    const [ref, setAutoFit] = useAutoFit()

    const { value } = useDerive('exec', {
        options: {
            method: SERVICE.EXEC_QUERY_METHOD,
            body: { name: '39b0a11e05' }
        },
        path: 'message'
    })

    useEffect(() => {
        setTimeout(() => {
            setAutoFit()
        }, 200)
    }, [])

    const columns: any = [
        {
            key: '1',
            name: 'Order Number',
            fieldName: 'DocEntry',
            minWidth: 50,
            maxWidth: 50,
            isResizable: true
        },
        {
            key: '2',
            name: 'Business Partner',
            fieldName: 'CardName',
            minWidth: 100,
            maxWidth: 100,
            isResizable: true
        },
        {
            key: '3',
            name: 'Order Ref',
            fieldName: 'NumAtCard',
            minWidth: 100,
            maxWidth: 100,
            isResizable: true
        },
        {
            key: '4',
            name: 'Order Date',
            fieldName: 'DocDate',
            minWidth: 110,
            maxWidth: 110,
            isResizable: true
        },
        {
            key: '5',
            name: 'Order Value',
            fieldName: 'DocTotal',
            minWidth: 200,
            isResizable: true
        },
        {
            key: '6',
            name: 'Mode of Delivery',
            fieldName: 'ModeOfDelivery',
            minWidth: 200,
            isResizable: true
        },
        {
            key: '7',
            name: 'Payment Term',
            fieldName: 'PymntGroup',
            minWidth: 200,
            isResizable: true
        }
    ]

    const onRenderItemColumn = (obj: any, index: any, column: any) => {
        const fieldContent = _.get(obj, column.fieldName);
        switch (column.fieldName) {
            case 'Action':
                return (
                    null
                    // <div className={'cursor-pointer'} onClick={() => deleteActionHandler(index)}><FontIcon className="w-[4px] h-[4px]" iconName="Completed" /></div>
                )
            default:
                return (
                    <>{fieldContent}</>
                )
        }
    }

    return (
        <Box>
            <div ref={ref} className={"overflow-auto"}>
                <DetailsList
                    items={value ?? []}
                    columns={columns}
                    compact={true}
                    setKey="none"
                    onRenderItemColumn={onRenderItemColumn}
                    layoutMode={DetailsListLayoutMode.justified}
                    selectionMode={0}
                />
            </div>
        </Box>
    )

}

export default PrepaymentOrders