
import { CoreUIProvider } from "@dladio/core-ui";
import Home from "./components/pages/home/Home";
import Template1 from "./examples/Template1/Template1";


function App() {
  return (
    <CoreUIProvider>
        <Home />
    </CoreUIProvider >
  );
}

export default App;
