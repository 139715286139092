import { Box } from "@dladio/core-ui"
import { localStoreThunk, useAutoFit, useLocalStore } from "@dladio/hooks"
import { SERVICE, useDerive } from "@dladio/service"
import { multiSearch } from "@dladio/utils"
import { getFocusStyle, getTheme, ITheme, List, mergeStyleSets, SearchBox, Text } from "@fluentui/react"
import _ from "lodash"
import moment from "moment"
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"

const { setStore: setBRStore } = localStoreThunk("br")

const LeftDetailList = () => {

    const { model: filters } = useLocalStore('filters')
    console.log(filters);

    const { value } = useDerive('exec', {
        options: {
            method: SERVICE.EXEC_QUERY_METHOD,
            body: { name: '787ee1a21a', ...filters }
        },
        path: 'message'
    })

    console.log(value, '-----');


    return (
        <DataListWrapper data={value ?? []} />
    )
}

const DataListWrapper = ({ data }: any) => {

    const dispatch = useDispatch()
    const [ref, setAutoFit] = useAutoFit()
    const [list, setList] = useState()

    useEffect(() => {
        setTimeout(() => {
            setAutoFit()
        }, 200)
    }, [])

    useEffect(() => {
        setList(data)
    }, [data])

    const searchTextChangeHandler = (e: any, text: any) => {
        const filteredLines = text && data?.length > 0 ? multiSearch(data, text) : data
        setList(filteredLines)
    }

    const markup = (
        <DataList data={list} onSelect={(index: any, item: any) => {
            dispatch(setBRStore('selected-statement', item) as any)
            dispatch(setBRStore('selected-index', _.get(item, 'Sequence')) as any)
        }} />
    )

    return (
        <Box>
            <SearchBox onChange={searchTextChangeHandler} className="m-2 rounded-2" placeholder="Search" />
            <div className={'bg-white rounded-2'}>
                <div ref={ref} className="overflow-auto">
                    {markup}
                </div>
            </div>
        </Box>
    )
}

const DataList = (props: any) => {
    const [selectedIndex, setSelectedIndex] = useState()

    const onRenderCell: any = (
        item: any,
        index: number,
        isScrolling: boolean
    ) => (
        <ItemCell key={index} item={item} onSelect={(index: any, item: any) => {
            setSelectedIndex(index)
            props?.onSelect(index, item)
        }} index={index} isSelected={index === selectedIndex} />
    )

    const markup = <List items={props?.data ?? []} onRenderCell={onRenderCell} />

    return (
        <>
            {markup}
        </>
    )
}

const ItemCell = ({ index, onSelect, item, isSelected }: any) => {

    const { select } = useLocalStore('br')

    return (
        <div onClick={() => onSelect(index, item)} tabIndex={index}
            className={classNames.itemCell + ' ' + 'cursor-pointer'} data-is-focusable={true}
            style={{ backgroundColor: (select('selected-index') === _.get(item, 'Sequence')) ? '#f3f2f1' : '' }}>
            <div className={'ml-[10px] overflow-hidden flex-grow-1'}>
                <Text className=" font-semibold" variant={'smallPlus'}>{item?.Memo} - {item?.Memo2}</Text>
                <br />
                <Text variant={'xSmall'}>{item?.Sequence}</Text>
                <div className="flex gap-5">
                    <div className="w-1/3"><Text variant={'small'}>Posting Date</Text></div>
                    <div><Text variant={'small'}>{moment(item?.PostingDate).format('DD-MM-YYYY')}</Text></div>
                </div>
                <div className="flex gap-5">
                    <div className="w-1/3"><Text variant={'small'}>Debit</Text></div>
                    <div><Text variant={'small'}>{item?.DebAmount}</Text></div>
                </div>
                <div className="flex gap-5">
                    <div className="w-1/3"><Text variant={'small'}>Credit</Text></div>
                    <div><Text variant={'small'}>{item?.CredAmnt}</Text></div>
                </div>
            </div>
        </div>
    )
}

const theme: ITheme = getTheme();
const { palette, semanticColors } = theme;

const classNames = mergeStyleSets({
    itemCell: [
        getFocusStyle(theme, { inset: -1 }),
        {
            minHeight: 54,
            padding: 10,
            boxSizing: "border-box",
            borderBottom: `1px solid ${semanticColors.bodyDivider}`,
            display: "flex",
            selectors: {
                "&:hover": { background: palette.neutralLight },
            },
        },
    ]
});

export default LeftDetailList