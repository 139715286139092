import { erpMethodApi, SERVICE } from "@dladio/service";
import { MessageBarType } from "@fluentui/react";

export const createReconcile = (model: any, toast: any) => {
    return async (dispatch: any) => {
        // dispatch(messageAction.show({title: 'Processing.....', type: MessageBarType.info}))
        // dispatch(showLoading("Loading..."));
        const { error, data, code } = await dispatch(erpMethodApi.endpoints.execMutationApi.initiate({
            method: SERVICE.EXEC_SAP_SERVICE,
            body: {
                method: "POST",
                endpoint: "ExternalReconciliationsService_Reconcile",
                body: model
            }
        }))
        // dispatch(hideLoading());
        if (error) {
            toast({
                title: 'Failed [Bank Reconciliation]',
                description: error?.data?.message?.message?.value,
                status: 'error',
                duration: 2500,
                position: 'bottom-end',
                isClosable: true,
            })
            return error
        } else {
            toast({
                title: 'Success [Bank Reconciliation]',
                status: 'success',
                duration: 2500,
                position: 'bottom-end',
                isClosable: true,
            })
        }
    }
}

export const createJournalEntry = (body: any, toast: any) => {
    return async (dispatch: any) => {
        // dispatch(messageAction.show({ title: 'Processing.....', type: MessageBarType.info }))
        // dispatch(showLoading("Loading..."));
        const { error, data } = await dispatch(erpMethodApi.endpoints.execMutationApi.initiate({
            method: SERVICE.EXEC_SAP_SERVICE,
            body: {
                method: "POST",
                endpoint: "JournalEntries",
                body: body
            }
        }))
        // dispatch(hideLoading());
        error ? toast({
            title: 'Failed [Journal Entry]',
            description: error?.data?.message?.message?.value,
            status: 'error',
            duration: 2500,
            position: 'bottom-end',
            isClosable: true,
        }) : toast({
            title: 'Success [Journal Entry]',
            status: 'success',
            duration: 2500,
            position: 'bottom-end',
            isClosable: true,
        })

        return data?.message
    }
}

export const getReconcileLines = (selectedList: any,) => {
    let lines: any = []
    let data: any = selectedList
    for (let [index, item] of data.entries()) {
        let line = { TransactionNumber: item.TransactionId, LineNumber: item.LineNumber }
        lines.push(line)
    }
    return lines
}

export const cancelJournalEntry = (body: any, id: any, toast: any) => {
    return async (dispatch: any) => {
        const { error, data } = await dispatch(erpMethodApi.endpoints.execMutationApi.initiate({
            method: SERVICE.EXEC_SAP_SERVICE,
            body: {
                method: "POST",
                endpoint: `JournalEntries(${id})/Cancel`,
                body: body
            }
        }))
        if (error) {
            toast({
                title: 'Failed [Journal Entry]',
                description: error?.data?.message?.message?.value,
                status: 'error',
                duration: 2500,
                position: 'bottom-end',
                isClosable: true,
            })

        } else {
            return data?.message
        }
    }
}

export const createPayment = (model: any) => {
    return async (dispatch: any) => {
        const { error, data }: any = await dispatch(
            erpMethodApi.endpoints.execMutationApi.initiate({
                method: 'generate_payment_model_for_sales_invoice',
                body: { model: model },
            })
        );
        return data?.message
    }
}
export const getAccountNameAction = (docEntry: any) => {
    return async (dispatch: any) => {
        const { error, data } = await dispatch(erpMethodApi.endpoints.execMutationApi.initiate({
            method: SERVICE.EXEC_QUERY_METHOD,
            body: {
                name: "ce62f6ad3c",
                id: docEntry
            }
        }))
        return data?.message
    }
}


