import { Stack, TitleBar } from "@dladio/core-ui"
import Banner from "./components/Banner"
import AddJournalEntryDialog from "./components/Dialogs/CreateJE"
import MarkAsPaidDialog from "./components/Dialogs/MarkAsPaid"
import FilterPanel from "./components/FilterPanal"
import LeftDetailList from "./components/LeftDetailList"
import MainView from "./components/Pivot/MainView"

const Home = () => {
    return (
        <>
            <TitleBar title="Bank Reconciliation" subTitle={`Bank Reconciliation`} />
            <FilterPanel />
            <Stack>
                <Stack className={"w-1/4"}>
                    <LeftDetailList />
                </Stack>
                <Stack className={"flex-col w-3/4"}>
                    <Banner />
                    <MainView />
                </Stack>
            </Stack>
            <MarkAsPaidDialog />
            <AddJournalEntryDialog />
        </>
    )
}

export default Home